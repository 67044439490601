import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`,
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5],
    },
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`],
  },
}

export default (props) => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='Tentang Saya' />
      <Divider />
      <Stack>
        <Main>
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
          </Box>
          <Divider />
          <PageTitle
            header='Tentang Saya'
            subheader='Saya adalah seorang pengembang perangkat lunak. Saya lebih banyak berkecimpung di dunia backend, namun akhir-akhir ini saya sedang belajar pengembangan aplikasi frontend.'
          />
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Perkenalan'>
                <Card variant='paper'>
                  <Text variant='p'>
                    Nama saya{' '}
                    <Text variant='em' as='em'>
                      Husni Adil Makmur
                    </Text>
                    , biasa dipanggil{' '}
                    <Text variant='em' as='em'>
                      Husni
                    </Text>
                    .
                  </Text>
                  <Text variant='p'>
                    Saya dari kecil lahir dan sampe saat ini tinggal di{' '}
                    <Text variant='em' as='em'>
                      Daerah Istimewa Yogyakarta
                    </Text>
                    .
                  </Text>
                  <Text variant='p'>
                    Karena udah dari kecil saya tinggal di Jogja sampe sekarang,
                    artinya saya juga sekolah di Jogja terus mulai dari TK (
                    <Text variant='em' as='em'>
                      gak
                    </Text>{' '}
                    ikut pre-school / paud), SD, SLTP (iya waktu itu bukan SMP
                    namanya), SMU (sekarang menjadi SMA), hingga kuliah di{' '}
                    <u>perguruan tinggi</u>.
                  </Text>
                </Card>
              </Section>
              <Divider />
              <Section title='Media Sosial'>
                <Card variant='paper'>
                  Untuk{' '}
                  <Text variant='em' as='em'>
                    medsos
                  </Text>
                  , saya jarang aktif di <u>Facebook</u>, saya seringnya aktif
                  di <u>Instagram</u> dan <u>YouTube</u>, walau cuma jadi
                  penikmat saja, jarang posting juga. Bagi saya, media sosial
                  adalah sebagai tempat hiburan, bukan sebagai tempat untuk
                  menebar kebencian maupun menyebarkan berita palsu/
                  <Text variant='em' as='em'>
                    hoax
                  </Text>
                  .
                </Card>
              </Section>
              <Divider />
            </Box>
            <Box sx={styles.column}>
              <Section title='Pekerjaan'>
                <Card variant='paper'>
                  Saya bekerja sebagai{' '}
                  <Text variant='em' as='em'>
                    Software Engineer
                  </Text>{' '}
                  (insinyur perangkat lunak / pengembang perangkat lunak) di
                  sebuah perusahaan di Jakarta.
                </Card>
              </Section>
              <Divider />
              <Section title='Karakter'>
                <Card variant='paper'>
                  Sebenarnya saya agak tertutup orangnya (bahasa gaulnya:{' '}
                  <Text variant='em' as='em'>
                    introvert
                  </Text>
                  ), namun dengan memiliki blog ini, saya mencoba belajar untuk
                  mengekspresikan apa yang ada di dalam pikiran saya. Mungkin{' '}
                  bakal memakai bahasa campur aduk dan yang dibahas mungkin dari
                  berbagai macam topik, gak pake{' '}
                  <Text variant='em' as='em'>
                    niche-niche
                  </Text>{' '}
                  gituan.
                </Card>
              </Section>
              <Divider />
              <Section title='Keluarga'>
                <Card variant='paper'>
                  Saya adalah anak dari kedua orang tua saya. Saya adalah ayah
                  dari anak saya. Saya adalah suami dari istri saya. Saya adalah
                  kakak dari adik saya. Dan saya adalah teman dari teman-teman
                  saya, halah 😁
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query aboutQueryAndAboutQuery {
    photo: file(absolutePath: { regex: "/hero.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
